import { Service } from "./service";

export class UserService extends Service {
  static async signIn(credentials: API.LoginReq) {
    try {
      const response = await UserService.post<API.LoginReq, API.LoginResp>("/users/login", credentials);
      if (response?.data?.data?.accessToken && response.data.data.user) {
        return response?.data?.data;
      } else {
        throw new Error("Wrong login response");
      }
    } catch (err) {
      UserService.errorHandler(err);
    }
  }
  static async initPasswordReset(initPasswordResetData: API.InitPasswordResetReq): Promise<API.InitPasswordResetResp> {
    try {
      const response = await UserService.post<API.InitPasswordResetReq, API.InitPasswordResetResp>("/users/init-password-reset", initPasswordResetData);
      if (response?.data?.data?.success) {
        return response?.data.data;
      } else {
        throw new Error("Error password reset");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async passwordReset(passwordResetData: API.PasswordResetReq): Promise<API.PasswordResetResp> {
    try {
      const response = await UserService.post<API.PasswordResetReq, API.PasswordResetResp>("/users/password-reset", passwordResetData);
      if (response?.data?.data?.success) {
        return response?.data.data;
      } else {
        throw new Error("Error password reset");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async getUserdata(accessToken: string): Promise<API.GetUserdataResp> {
    try {
      const response = await UserService.get<API.GetUserdataResp>("/users/get-userdata", accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting userdata");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async getInvoices(accessToken: string): Promise<API.GetInvoicesResp> {
    try {
      const response = await UserService.get<API.GetInvoicesResp>("/users/get-invoices", accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error getting userdata");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async declineAgreement(accessToken: string): Promise<API.DeclineAgreementResp> {
    try {
      const response = await UserService.post<{}, API.DeclineAgreementResp>("/users/agreement/decline", {}, accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data
      } else {
        throw new Error("Error sending agreement");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async agreement(accessToken: string, agreement: API.AgreementReq): Promise<API.AgreementResp> {
    try {
      const response = await UserService.post<API.AgreementReq, API.AgreementResp>("/users/agreement", agreement, accessToken);
      if (response?.data?.data?.success && response?.data?.data?.profile) {
        return response?.data?.data;
      } else {
        throw new Error("Error sending agreement");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async updateProfile(accessToken: string, updateProfileReq: API.UpdateProfileReq): Promise<API.UserProfile> {
    try {
      const response = await UserService.post<API.UpdateProfileReq, API.UpdateProfileResp>("/users/update-profile", updateProfileReq, accessToken);
      if (response?.data?.data?.success && response?.data?.data?.profile) {
        return response?.data?.data.profile;
      } else {
        throw new Error("Error updating profile");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async addPromo(accessToken: string , promoCode: string): Promise<API.AddPromoResp> {
    try {
      const response = await UserService.post<API.AddPromoReq, API.AddPromoResp>("/users/add-promo", {promoCode}, accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error adding discount");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async updateCard(accessToken: string, updateCardReq: API.UpdateCardReq): Promise<API.UpdateCardResp> {
    try {
      const response = await UserService.post<API.UpdateCardReq, API.UpdateCardResp>("/subscriptions/update-card", updateCardReq, accessToken);
      if (response?.data?.data?.success) {
        return response?.data?.data;
      } else {
        throw new Error("Error updating card");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async referralLink(accessToken: string): Promise<API.ReferralLinkResp> {
    try {
      const response = await UserService.get<API.ReferralLinkResp>("/users/referral-link", accessToken);
      if (response?.data?.data?.success && response?.data?.data?.referral) {
        return response?.data?.data;
      } else {
        throw new Error("Error referral link");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async cancelTrial(accessToken: string): Promise<API.UserSubscription> {
    try {
      const response = await UserService.get<API.CancelTrialResp>("/subscriptions/cancel-trial", accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error cancel trial");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async reactivateTrial(accessToken: string): Promise<API.UserSubscription> {
    try {
      const response = await UserService.get<API.ReactivateTrialResp>("/subscriptions/reactivate-trial", accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error reactivate trial");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async cancelSubscription(accessToken: string): Promise<API.UserSubscription> {
    try {
      const response = await UserService.get<API.CancelSubscriptionResp>("/subscriptions/cancel", accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error cancel subscription");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async pauseSubscription(accessToken: string, pauseSubscriptionReq: API.PauseSubscriptionReq): Promise<API.UserSubscription> {
    try {
      const response = await UserService.post<API.PauseSubscriptionReq , API.PauseSubscriptionResp>("/subscriptions/pause", pauseSubscriptionReq , accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error pause subscription");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async cancelSubscriptionInit(accessToken: string): Promise<string> {
    try {
      const response = await UserService.get<API.CancelSubscriptionInitResp>("/subscriptions/cancel-init", accessToken);
      if (response?.data?.data?.success && response?.data?.data?.userHash) {
        return response?.data?.data.userHash;
      } else {
        throw new Error("Error cancel subscription");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async reactivateSubscription(accessToken: string): Promise<API.UserSubscription> {
    try {
      const response = await UserService.get<API.ReactivateSubscriptionResp>("/subscriptions/reactivate", accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error reactivating subscription");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async unPauseSubscripton(accessToken: string): Promise<API.UserSubscription> {
    try {
      const response = await UserService.get<API.UnpauseSubscriptionResp>("/subscriptions/unpause", accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error unpausing subscription");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async activateAddon(accessToken: string, activateAddonReq: API.ActivateAddonReq): Promise<API.UserSubscription> {
    try {
      const response = await UserService.post<API.ActivateAddonReq, API.ActivateSubscriptionResp>("/subscriptions/activate-addon", activateAddonReq, accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error activating OTC addon");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async cancelAddon(accessToken: string, cancelAddonReq: API.CancelAddonReq): Promise<API.UserSubscription> {
    try {
      const response = await UserService.post<API.CancelAddonReq, API.CancelSubscriptionResp>("/subscriptions/cancel-addon", cancelAddonReq, accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error cancel OTC addon");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async reactivateAddon(accessToken: string, reactivateAddonReq: API.ReactivateAddonReq): Promise<API.UserSubscription> {
    try {
      const response = await UserService.post<API.ReactivateAddonReq, API.ReactivateSubscriptionResp>("/subscriptions/reactivate-addon", reactivateAddonReq, accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error reactivating OTC addon");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async activateSubscription(accessToken: string, activateSubscriptionReq: API.ActivateSubscriptionReq): Promise<API.UserSubscription> {
    try {
      const response = await UserService.post<API.ActivateSubscriptionReq, API.ActivateSubscriptionResp>("/subscriptions/activate", activateSubscriptionReq, accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error activating subscription. Your card was declined");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async upgradeTrial(accessToken: string): Promise<API.UserSubscription> {
    try {
      const response = await UserService.post<API.UpgradeSubscriptionReq, API.UpgradeSubscriptionResp>("/subscriptions/upgrade-trial", {}, accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error upgrade trial");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async upgradeSubscription(accessToken: string, upgradeSubscriptionReq: API.UpgradeSubscriptionReq): Promise<API.UserSubscription> {
    try {
      const response = await UserService.post<API.UpgradeSubscriptionReq, API.UpgradeSubscriptionResp>("/subscriptions/upgrade", upgradeSubscriptionReq, accessToken);
      if (response?.data?.data?.success && response?.data?.data?.subscription) {
        return response?.data?.data.subscription;
      } else {
        throw new Error("Error upgrade subscription");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
  static async getDiscount(accessToken: string, getDiscountReq: API.GetDiscountReq): Promise<API.DiscountData> {
    try {
      const response = await UserService.post<API.GetDiscountReq, API.GetDiscountResp>("/subscriptions/get-discount", getDiscountReq, accessToken);
      const data = response?.data?.data;
      if (data && data?.success && data?.discount) {
        return data.discount;
      } else {
        throw new Error("Coupon applying error");
      }
    } catch (err) {
      throw UserService.errorHandler(err);
    }
  }
}