import { Service } from "./service";

export class CheckoutService extends Service {
  static async getUpgradePrice(accessToken: string, getUpgradePriceReq: API.GetUpgradePriceReq): Promise<number> {
    try {
      const response = await CheckoutService.post<API.GetUpgradePriceReq, API.GetUpgradePriceResp>("/subscriptions/get-upgrade-price", getUpgradePriceReq, accessToken);
      if (response?.data?.data.success && response?.data?.data?.upgradePrice) {
        return response.data.data.upgradePrice;
      } else {
        throw new Error("GetUpgradePriceResp has wrong format");
      }
    } catch (err) {
      throw CheckoutService.errorHandler(err);
    }
  }

  static async createPaymentIntent(accessToken: string, createPaymentIntentReq: API.CreatePaymentIntentReq): Promise<API.CreatePaymentIntentData> {
    try {
      const response = await CheckoutService.post<API.CreatePaymentIntentReq, API.CreatePaymentIntentResp>("/subscriptions/create-payment-intent", createPaymentIntentReq, accessToken);
      if (response?.data?.data.success && response?.data?.data?.clientSecret) {
        return {
          clientSecret: response.data.data.clientSecret,
          subscriptionId: response.data.data.subscriptionId
        };
      } else {
        throw new Error("CreatePaymentIntentResp has wrong format");
      }
    } catch (err) {
      throw CheckoutService.errorHandler(err);
    }
  }

  static async createSetupIntent(accessToken: string): Promise<API.CreateSetupIntentData> {
    try {
      const response = await CheckoutService.post<null, API.CreateSetupIntentResp>("/subscriptions/create-setup-intent", null , accessToken);
      if (response?.data?.data.success && response?.data?.data?.clientSecret) {
        return {
          clientSecret: response.data.data.clientSecret
        };
      } else {
        throw new Error("CreateSetupIntentResp has wrong format");
      }
    } catch (err) {
      throw CheckoutService.errorHandler(err);
    }
  }
}