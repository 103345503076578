import { AnyEventObject, assign, createMachine, EventObject, interpret } from "xstate";
import { useActor } from "@xstate/vue";

export const validActions = ["activate-addon", "activate-sub", "upgrade-trial", "upgrade-sub"] as const;

export type Action = typeof validActions[number];

interface ActivationSubContext {
  action?: Action;
  plan?: API.Plan;
  addons?: API.Addon[];
  coupon?: CouponDiscount;
}

interface StartEvent extends EventObject {
  action: Action;
  addons?: API.Addon[];
}

interface PlanSelectedEvent extends EventObject {
  plan: API.Plan;
}

interface AddonSelectedEvent extends EventObject {
  addons: API.Addon[];
}

interface CouponAppliedEvent extends EventObject {
  coupon: CouponDiscount;
}

export interface CouponDiscount {
  code: string;
  discount: null | {
    type: "percent" | "fixed";
    value: number;
  }
}


const activationSubMachine = createMachine(
  {
    predictableActionArguments: true,
    id: "activateSub",
    initial: "initState",
    schema: {
      context: {} as ActivationSubContext,
      events: {} as EventObject | { type: "START", action: Action, addons?: API.Addon[] } | PlanSelectedEvent | AddonSelectedEvent | CouponAppliedEvent
    },
    context: {
      action: undefined,
      plan: undefined,
      addons: undefined,
      coupon: undefined
    },
    states: {
      initState: {
        on: {
          START: [
            {
              target: "checkout",
              cond: "isActivationAddon",
              actions: assign<ActivationSubContext, AnyEventObject>({
                action: (context, event) => event.action,
                addons: (context, event) => event.addons
              })
            },
            {
              target: "planSelect",
              actions: assign<ActivationSubContext, AnyEventObject>({
                action: (context, event) => event.action,
              })
            }
          ]
        }
      },
      planSelect: {
        on: {
          PLAN_SELECTED: {
            target: "checkout", // No more addon select
            actions: assign<ActivationSubContext, AnyEventObject>({
              plan: (context, event) => event.plan
            })
          }
        },
      },
      addonSelect: {
        on: {
          ADDON_SELECTED: {
            target: "checkout",
            actions: assign<ActivationSubContext, AnyEventObject>({
              addons: (context, event) => event.addons
            })
          },
          BACK: "planSelect",
        }
      },
      checkout: {
        on: {
          BACK: "planSelect",
          COUPON_APPLIED: {
            target: "checkout",
            actions: assign<ActivationSubContext, AnyEventObject>({
              coupon: (context, event) => event.coupon
            })
          },
          COUPON_REMOVED: {
            target: "checkout",
            actions: assign<ActivationSubContext, AnyEventObject>({
              coupon: undefined
            })
          }
        },
      }
    }
  },
  {
    guards: {
      isActivationAddon: (context, event: AnyEventObject) => {
        if (event.type === "START") {
          return event.action === "activate-addon"
        }
        return false;
      }
    }
  }
);


type Getters = {
  getAction: () => Action | null;
  getPlan: () => API.Plan | null;
  getAddons: () => API.Addon[];
  getCoupon: () => CouponDiscount | null;
  couponEnabled: () => boolean;
}

export function createActivationService() {
  return interpret(activationSubMachine).start();
}

// Create a custom service hook for this machine, that takes the service previously created. This way you don't have to import on every component the useService hook and the service created.
export const useActivationSubMachine = (service: ReturnType<typeof createActivationService>) => {
  const actor = useActor(service);
  type Actor = typeof actor & Getters;
  (actor as Actor).getAction = function () {
    return this.state.value.context.action ? this.state.value.context.action : null;
  };
  (actor as Actor).getPlan = function () {
    return this.state.value.context.plan ? this.state.value.context.plan : null;
  };
  (actor as Actor).getAddons = function () {
    return this.state.value.context.addons ? this.state.value.context.addons : [];
  };
  (actor as Actor).getCoupon = function () {
    return this.state.value.context.coupon ? this.state.value.context.coupon : null;
  };
  (actor as Actor).couponEnabled = function () {
    return this.state.value.context.action !== "activate-addon";
  };
  return actor as Actor;
}