import { defineStore } from 'pinia';
import { ConfigService } from "@/services/config";
import { CheckoutService } from '@/services/checkout';
import { getAccessToken } from './auth';

export interface ConfigState {
    captcha: {
        key: string;
        validateCaptchaForLogin: boolean;
        validateCaptchaForRegistration: boolean;
        validateCaptchaForVerifyEmailOtp: boolean;
        validateCaptchaForResendEmailOtp: boolean;
        validateCaptchaForEmailEdit: boolean;
        validateCaptchaForInitPasswordReset: boolean;
        validateCaptchaForPasswordReset: boolean;
        validateCaptchaForActivateTrial: boolean;
    }
    plans: API.Plan[];
    plansMap: Map<string, API.Plan>;
    allAddons: API.Addon[];
    paymentSecret: string | null;
    setupSecret: string | null;
    subscriptionId: string | null;
    // chargebee?: {
    //     payment: {
    //         site: string;
    //         publicKey: string;
    //     }
    // },
    stripe?: {
        payment: {
            publicKey: string;
            apiVersion: string;
            promoActive?: string;
        }
    }
}

export const useConfigStore = defineStore('config', {
    state: (): ConfigState => {
        return {
            captcha: {
                key: "",
                validateCaptchaForLogin: false,
                validateCaptchaForRegistration: true,
                validateCaptchaForVerifyEmailOtp: true,
                validateCaptchaForResendEmailOtp: true,
                validateCaptchaForEmailEdit: true,
                validateCaptchaForInitPasswordReset: true,
                validateCaptchaForPasswordReset: true,
                validateCaptchaForActivateTrial: true
            },
            plans: [],
            plansMap: new Map(),
            allAddons: [],
            paymentSecret: null,
            setupSecret: null,
            subscriptionId: null,
        };
    },
    actions: {
        async getConfig() {
            try {
                const config = await ConfigService.getConfig();
                this.captcha = config.captchaConfig;
                this.plans = config.plans.sort(comparePlans);
                this.plansMap.clear();
                this.plans.forEach(p => {
                    this.plansMap.set(p.code, p);
                })

                this.plans.forEach(plan => {
                    plan.addons.forEach(addon => {
                        if (!this.allAddons.find(a => a.code === addon.code)) {
                            this.allAddons.push(addon);
                        }
                    });
                });

                // this.chargebee = config.chargebee;
                this.stripe = config.stripe;
            } catch (err) {
                console.error("Error getting app config", err);
            }
        },
        async createPaymentIntent(createPaymentIntentReq: API.CreatePaymentIntentReq) {
          try {
            const subscription = await CheckoutService.createPaymentIntent(getAccessToken(), createPaymentIntentReq);
            return subscription?.clientSecret
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        async createSetupIntent() {
          try {
            const setupIntent = await CheckoutService.createSetupIntent(getAccessToken());
            return setupIntent?.clientSecret
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        setPaymentSecret(paymentSecret: string | null) {
          if(!paymentSecret) {
            this.paymentSecret = null;
            return;
          }
          this.paymentSecret = paymentSecret;
        },
        setSetupSecret(setupSecret: string | null) {
          if(!setupSecret) {
            this.setupSecret = null;
            return;
          }
          this.setupSecret = setupSecret;
        }
    },
    getters: {
        planCost(state) {
            return (planCode: string) => {
                const plan = state.plansMap.get(planCode);
                return plan?.unitAmount ? plan.unitAmount : 0;
            }
        },
        planCostFormatted() {
            const planCost = this.planCost;
            return (planCode: string, precision: number = 0) => {
                return `$${(planCost(planCode) / 100).toFixed(precision)}`;
            }
        },
        addonCost(state) {
            return (addon: API.Addon, planCode?: string | null) => {
                if (!addon || !planCode) {
                    return 0;
                } else {
                    return addon.unitAmount;
                }
            }
        },
        addonCostFormatted() {
            const addonCost = this.addonCost;
            return (addon: API.Addon, planCode?: string | null, precision: number = 0) => {
                return `$${(addonCost(addon, planCode) / 100).toFixed(precision)}`;
            }
        }
    }
})

const comparePlans = (a: API.Plan, b: API.Plan) => {
    if (a.name < b.name) {
        return 1;
    }
    if (a.name > b.name) {
        return -1;
    }
    return 0;
}