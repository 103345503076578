import { UserService } from '../services/user';
import { defineStore } from 'pinia';
import { useAuthStore, getAccessToken } from './auth';
import { SignupService } from '@/services/signup';

export interface UserState {
    profile: API.UserProfile | null;
    subscription: SubscriptionFormatted | null;
    billingInfo: API.UserBillingInfo | null;
}

export interface SubscriptionFormatted extends API.Subscription {
    isTrial?: boolean | null;
    daysRemaining?: number;
    currentPeriodEndDate?: Date;
    endFormatted?: string;
    totalUnitAmount?: number;
    scheduledSubscription: SubscriptionFormatted | null;
}

export const useUserStore = defineStore('user', {
    state: (): UserState => {
        return {
            profile: null,
            subscription: null,
            billingInfo: null
        }
    },
    getters: {
        phone: (state) => {
            return state.profile?.phone
        },
        fullName: (state) => {
            return `${state.profile?.firstName} ${state.profile?.lastName}`;
        }
    },
    actions: {
        async getInvoices() {
          const response = await UserService.getInvoices(getAccessToken());
          if (response.success && response.invoices) {
            return response.invoices;
          }
        },
        async updateUserdata() {
            const response = await UserService.getUserdata(getAccessToken());
            if (response.success && response.userdata) {
                this.setAll(response.userdata);
            }
            return response;
        },
        async updateProfile(updateProfileReq: API.UpdateProfileReq) {
            try {
                const profile = await UserService.updateProfile(getAccessToken(), updateProfileReq);
                this.setProfile(profile);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async referralLink() {
          try {
              const referralLink = await UserService.referralLink(getAccessToken());
              return referralLink
          } catch (err) {
              console.error(err);
              throw err;
          }
      },
        async updateCard(updateCardReq: API.UpdateCardReq) {
            try {
                const response = await UserService.updateCard(getAccessToken(), updateCardReq);
                if(response?.billingInfo) this.setBillingInfo({...this.billingInfo , ...response.billingInfo} );
                return response;
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async cancelSubscription() {
            try {
                const subscription = await UserService.cancelSubscription(getAccessToken());
                this.setSubscription(subscription);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async pauseSubscription(pauseDuration: number) {
          try {
              const subscription = await UserService.pauseSubscription(getAccessToken() , {pauseDuration});
              this.setSubscription(subscription);
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        async cancelTrial() {
          try {
              const subscription = await UserService.cancelTrial(getAccessToken());
              this.setSubscription(subscription);
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        async reactivateTrial() {
          try {
              const subscription = await UserService.reactivateTrial(getAccessToken());
              this.setSubscription(subscription);
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        async cancelSubscriptionInit() {
          try {
              const auth = useAuthStore();
              if (!auth.accessToken) {
                  return;
              }
              return await UserService.cancelSubscriptionInit(auth.accessToken);
          } catch (err) {
              console.error(err);
              throw err;
          }
        },
        async reactivateSubscription() {
            try {
                const subscription = await UserService.reactivateSubscription(getAccessToken());
                this.setSubscription(subscription);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async unPauseSubscripton() {
          try {
            const subscription = await UserService.unPauseSubscripton(getAccessToken());
            this.setSubscription(subscription);
          } catch (err) {
            console.error(err);
            throw err;
          }
        },
        async cancelAddon(canelAddonReq: API.CancelAddonReq) {
            try {
                const subscription = await UserService.cancelAddon(getAccessToken(), canelAddonReq);
                this.setSubscription(subscription);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async activateAddon(activateAddonReq: API.ActivateAddonReq) {
            try {
                const subscription = await UserService.activateAddon(getAccessToken(), activateAddonReq);
                this.setSubscription(subscription);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async reactivateAddon(reactivateAddonReq: API.ReactivateAddonReq) {
            try {
                const subscription = await UserService.reactivateAddon(getAccessToken(), reactivateAddonReq);
                this.setSubscription(subscription);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async activateTrial(planSelected: string) {
          try {
            const response = await SignupService.activateTrial(getAccessToken() , planSelected);
            if(response.subscription) this.setSubscription(response.subscription);
          } catch (err) {
              console.error(err);
              throw err;
          }
      },
        async activateSubscription(activateSubscriptionReq: API.ActivateSubscriptionReq) {
            try {
                const subscription = await UserService.activateSubscription(getAccessToken(), activateSubscriptionReq);
                this.setSubscription(subscription);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async upgradeTrial() {
            try {
                const subscription = await UserService.upgradeTrial(getAccessToken());
                this.setSubscription(subscription);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async upgradeSubscription(upgradeSubscriptionReq: API.UpgradeSubscriptionReq) {
            try {
                const subscription = await UserService.upgradeSubscription(getAccessToken(), upgradeSubscriptionReq);
                this.setSubscription(subscription);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async addPromo(promoCode: string) {
          return await UserService.addPromo(getAccessToken(), promoCode);
        },

        //registration actions
        async register(signupData: API.RegisterReq) { //todo check usage
            const response = await SignupService.register(signupData);
            if (response.success && response.accessToken) {
                const auth = useAuthStore();
                auth.setToken(response.accessToken);
            }
            return response;
        },
        async verifyEmail(verifyEmailData: API.VerifyEmailReq) { //todo check usage
            return await SignupService.verifyEmail(getAccessToken(), verifyEmailData);
        },
        async resendEmailOtp(resendEmailOtpData: API.ResendEmailOtpReq) { //todo check usage
            return await SignupService.resendEmailOtp(getAccessToken(), resendEmailOtpData);
        },
        async changeEmail(changeEmailData: API.ChangeEmailReq) {
            const response = await SignupService.changeEmail(getAccessToken(), changeEmailData);
            if (response.success && response.accessToken) {
                const auth = useAuthStore();
                auth.setToken(response.accessToken);
            }
            return response;
        },
        async declineAgreement() {
            return await UserService.declineAgreement(getAccessToken());
        },
        async agreement(agreement: API.AgreementReq) {
            try {
                const agreementData = await UserService.agreement(getAccessToken(), agreement);
                this.setProfile(agreementData.profile);
                this.setSubscription(agreementData.subscription);
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async initPasswordReset(initPasswordResetData: API.InitPasswordResetReq) {
            return await UserService.initPasswordReset(initPasswordResetData);
        },
        async passwordReset(passwordResetData: API.PasswordResetReq) {
            return await UserService.passwordReset(passwordResetData);
        },

        //setters
        setProfile(profile: API.UserProfile | null) {
            this.profile = profile;
        },
        setSubscription(subscription: API.UserSubscription | null) {
            if (!subscription) {
                this.subscription = null;
                return;
            }
            this.subscription = prepareSuscription(subscription);
        },
        setBillingInfo(billingInfo: API.UserBillingInfo | null) {
            this.billingInfo = billingInfo;
        },
        setAll(user: API.UserData) {
            this.profile = user.profile;
            this.setSubscription(user.subscription);
            this.billingInfo = user.billingInfo;
        },
        clearAll() {
            this.profile = null;
            this.subscription = null;
            this.billingInfo = null;
        }

    }
});

function prepareSuscription(sub: API.Subscription | null): SubscriptionFormatted | null {
    if (!sub) {
        return null;
    }
    const prepared: SubscriptionFormatted = {
        isTrial: sub.plan.code?.startsWith("trial"),
        scheduledSubscription: null,
        ...sub
    };

    if (sub.currentPeriodEndsAt || sub?.scheduledChange?.currentPeriodStartsAt) {
      const endPeriod = sub.plan.name === 'Trial' && sub.scheduledChange ? sub.scheduledChange.currentPeriodStartsAt : sub.currentPeriodEndsAt;
      prepared.currentPeriodEndDate = new Date(endPeriod as number + 1000);

      const parts = endDateFormat.formatToParts(prepared.currentPeriodEndDate);
        prepared.endFormatted = `${parts.find(p => p.type === "month")?.value} ${parts.find(p => p.type === "day")?.value}, ${parts.find(p => p.type === "year")?.value}`;
    }

    if (prepared.unitAmount) {
        prepared.totalUnitAmount = prepared.unitAmount;
    }

    if (sub.state != "expired" && prepared.currentPeriodEndDate) {
        prepared.daysRemaining = Math.ceil((prepared.currentPeriodEndDate.getTime() - (new Date()).getTime()) / (1000 * 60 * 60 * 24));
    }

    if (sub.scheduledChange) {
      prepared.scheduledSubscription = prepareSuscription(sub.scheduledChange);
    }

    return prepared;
}

const endDateFormat = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
});