import { AnyEventObject, assign, createMachine, EventObject, interpret } from "xstate";
import { useActor } from "@xstate/vue";

interface SignupContext {
  email?: string;
  phone?: string;
}

interface InitSignupEvent extends EventObject {
  email: string;
}



const signupMachine = createMachine({
  predictableActionArguments: true,
  id: "signup",
  initial: "initSignup",
  schema: {
    context: {} as SignupContext,
    events: {} as EventObject | InitSignupEvent
  },
  context: {
    email: undefined,
    phone: undefined
  },
  states: {
    initSignup: {
      on: {
        INIT_SUCCESS: {
          target: "verifyEmail",
          actions: assign<SignupContext, AnyEventObject>({
            email: (context, event) => event.email
          })
        }
      },
    },
    editEmail: {
      on: {
        EMAIL_EDIT_COMPLETE: {
          target: "verifyEmail",
          actions: assign<SignupContext, AnyEventObject>({
            email: (context, event) => event.email
          })
        },
        EMAIL_EDIT_CANCEL: "verifyEmail",
      }
    },
    verifyEmail: {
      on: {
        EMAIL_VERIFIED: "completeSignup",
        EDIT_EMAIL: "editEmail"
      },
    },
    completeSignup: {
      on: {
        RESET: {
          target: "initSignup",
          actions: assign<SignupContext, AnyEventObject>({
            email: () => undefined,
          })
        }
      }
    }
  },
});

const service = interpret(signupMachine).start();

type Getters = {
  getEmail: () => string;
}

// Create a custom service hook for this machine, that takes the service previously created. This way you don't have to import on every component the useService hook and the service created.
export const useSignupMachine = () => {
  const actor = useActor(service);
  type Actor = typeof actor & Getters;
  (actor as Actor).getEmail = function () {
    return this.state.value.context.email ? this.state.value.context.email : "";
  };
  console.log("I have actor",actor);
  return actor as Actor;
}