import {createWebHistory, createRouter} from "vue-router";
import LoginForm from "./components/signup/LoginForm.vue";
import SignupForm from "./components/signup/SignupForm.vue";
import Signup2Form from "./components/signup/Signup2Form.vue"
import InitPasswordReset from "./components/signup/InitPasswordReset.vue";
import PasswordReset from "./components/signup/PasswordReset.vue";
import Agreement from "./components/signup/UserAgreement.vue";
import MainDashboard from "./components/dashboard/MainDashboard.vue";
import DownloadsPage from "./components/downloads/DownloadsPage.vue";
import SubscriptionPage from "./components/subscription/SubscriptionPage.vue";
import ProfilePage from "./components/profile/ProfilePage.vue";
import BillingInfoUpdate from "./components/billing/BillingInfoUpdate.vue";
import ActivateSubscription from "./components/subscription/ActivateSubscription.vue";
import CancelSubscription from "./components/subscription/CancelSubscription.vue";
import ActivateAddon from "./components/subscription/ActivateAddon.vue";
import RemoveAddon from "./components/subscription/RemoveAddon.vue";
import EnterCard from "./components/signup/EnterCard.vue";
import GettingStarted from "./components/dashboard/GettingStarted.vue";
import UpgradeTrial from "./components/subscription/UpgradeTrial.vue";
import InvoicesPage from "./components/profile/InvoicesPage.vue";
import ReferAFriendPage from "./components/profile/ReferAFriendPage.vue";
import CallbackPage from "./components/signup/CallbackPage.vue";
import EnterCardPromo from "./components/signup/EnterCardPromo.vue";

import {useAuthStore} from "./store/auth";
import {useUserStore} from "./store/user";
import {faro} from "@grafana/faro-web-sdk";

const publicPages = new Set(["/login", "/signup", "/signup-retry", "/init-password-reset", "/callback"]);
const publicParametrizedPages = [/\/password-reset\/.*$/];
const stateRestrictedPages = new Set<string>([]);
const privatePages = new Set([
    ...stateRestrictedPages,
    "/dashboard",
    "/dashboard/downloads",
    "/dashboard/profile",
    "/dashboard/billing-update",
    "/dashboard/subscription",
    "/dashboard/subscription-activate",
    "/dashboard/subscription-change",
    "/dashboard/subscription-cancel",
    "/dashboard/addon-activate",
    "/dashboard/addon-remove",
    "/dashboard/getting-started",
    "/dashboard/trial-upgrade",
    "/dashboard/refer-a-friend",
    "/dashboard/invoices",
    "/enter-card",
    "/enter-card-promo",
    "/agreement"
]);
const allPages = new Set([...privatePages, ...publicPages]);


const routes = [
    {
        name: "Root",
        path: "/",
        component: LoginForm
    },
    {
        name: "Callback",
        path: "/callback",
        component: CallbackPage
    },
    {
        name: "Login",
        path: "/login",
        component: LoginForm
    },
    {
        name: "SignUp",
        path: "/signup",
        component: SignupForm
    },
    {
        name: "SignUp2",
        path: "/signup-retry",
        component: Signup2Form
    },
    {
        name: "InitPasswordReset",
        path: "/init-password-reset",
        component: InitPasswordReset
    },
    {
        name: "PasswordReset",
        path: "/password-reset/:token",
        component: PasswordReset,
        props: true
    },
    {name: "Agreement", path: "/agreement", component: Agreement},
    {name: "CreditCard", path: "/enter-card", component: EnterCard},
    {name: "CreditCardPromo", path: "/enter-card-promo", component: EnterCardPromo},
    {
        name: "Dashboard",
        path: "/dashboard/",
        component: MainDashboard,
        children: [
            {path: "", component: DownloadsPage},
            {name: "GettingStarted", path: "getting-started", component: GettingStarted},
            {name: "Subscription", path: "subscription", component: SubscriptionPage},
            {name: "SubscriptionActivate", path: "subscription-activate", component: ActivateSubscription, props: true},
            {name: "SubscriptionCancel", path: "subscription-cancel", component: CancelSubscription},
            {name: "ActivateAddon", path: "addon-activate", component: ActivateAddon, props: true},
            {name: "TrialUpgrade", path: "trial-upgrade", component: UpgradeTrial, props: true},
            {name: "RemoveAddon", path: "addon-remove", component: RemoveAddon, props: true},
            {name: "BillingUpdate", path: "billing-update", component: BillingInfoUpdate},
            {name: "Downloads", path: "downloads", component: DownloadsPage},
            {name: "Invoices", path: 'invoices', component: InvoicesPage},
            {name: "ReferAFriend", path: 'refer-a-friend', component: ReferAFriendPage},
            {name: "Profile", path: "profile", component: ProfilePage}
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const {name, fullPath} = to;
    if (name) {
        faro.api.setView({name: name as string});
    }
    let validPage = allPages.has(to.path);
    let authRequired = privatePages.has(to.path);
    if (!validPage) {
        if (publicParametrizedPages.find(p => p.test(to.path))) {
            validPage = true;
            authRequired = false;
        }
    }
    const user = useUserStore();
    const auth = useAuthStore();

    let loggedIn = false;
    const activationState = user?.profile?.activationState;
    if (auth?.accessToken && (activationState === "activated" || activationState === "enter-card" || activationState === "enter-card-promo")) {
        loggedIn = true;
    }
    if (user?.profile?.username) {
        faro.api.setUser({username: user.profile.username})
    }
    // console.log("before each", to.path);
    if (validPage) {
        // console.log("valid page");
        if (authRequired) {
            // console.log("auth required page");
            if (loggedIn) {
                // console.log("logged in");

                // console.log("activationState", activationState);
                if (activationState === "activated") {
                    if (stateRestrictedPages.has(to.path)) {
                        // console.log("prevent state restricted page");
                        next("/dashboard");
                    } else {
                        // console.log("just next");
                        next();
                    }
                } else {
                    if (to.path === "/" + activationState) {
                        // console.log("just next");
                        next();
                    } else {
                        // console.log("readirect to state ", activationState);
                        next("/" + activationState);
                    }
                }
            } else {
                next("/login");
            }
        } else {
            // console.log("no auth required page");
            if (loggedIn) {
                // console.log("redirect dashboard");
                next("/dashboard");
            } else {
                // console.log("just next");
                next();
            }
        }
    } else {
        // console.log("invalid page");
        if (loggedIn) {
            // console.log("redirect dashboard");
            next("/dashboard");
        } else {
            // console.log("redirect login");
            next("/login");
        }
    }
});

export {router};