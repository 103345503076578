import {createApp} from 'vue'
import App from './App.vue'
import {router} from './router'
import {createPinia} from 'pinia';
import {useAuthStore} from './store/auth';
import {useConfigStore} from './store/config';
import {createVfm} from 'vue-final-modal'
import {Service} from './services/service';
import {defineRule, configure} from 'vee-validate';
import './index.css'
import {ConsoleTransport, FetchTransport, getWebInstrumentations, initializeFaro} from "@grafana/faro-web-sdk";
import {TracingInstrumentation} from "@grafana/faro-web-tracing";

const faro = initializeFaro({
    app: {
        name: 'Scanz',
        version: '0.1.0',
        environment: process.env.NODE_ENV
    },
    instrumentations: [
        ...getWebInstrumentations(),
        new TracingInstrumentation()
    ],
    transports: [
        new FetchTransport(
            {
                url: "https://v2.scanz.com/faro-account/collect",
                requestOptions: {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }
            }
        )],
    user: {
        id: localStorage.getItem('userId') ?? undefined
}
})
;

async function start() {
    configure({
        validateOnBlur: false,
    })

    const pinia = createPinia();

    const app = createApp(App)
        .use(pinia);

    const vfm = createVfm();
    const config = useConfigStore();
    const auth = useAuthStore();

    Service.init(auth.logout);

    await config.getConfig();
    await auth.initialize();
    app.config.errorHandler = (err, instance, info) => {
        // Wysłanie błędów Vue do Faro
        faro.api.pushError(err as Error);

        // Możesz zachować istniejące zachowanie błędów
        console.error('Vue Error:', err, info);
    };

    app
        .use(router)
        .use(vfm)
        // .use(
        //     createGtm({
        //         id: 'GTM-M2394DT',
        //         defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        //         compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        //         enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        //         debug: false, // Whether or not display console logs debugs (optional)
        //         loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        //         vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        //         ignoredViews: [], // Don't trigger events for specified router names (optional)
        //         trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
        //     })
        // )
        .mount('#app');

    const cardValidator = app.config.globalProperties.$cardFormat;

    defineRule("required", (value: string) => {
        if (!value || !value.length) {
            return "This field is required";
        }
        return true;
    });
    defineRule("checked", (value: boolean) => {
        if (!value) {
            return "You must agree";
        }
        return true;
    });
    defineRule("email", (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email || !re.test(email.toLowerCase())) {
            return "Should be a valid email";
        } else {
            return true;
        }
    });
    defineRule("password", (password: string) => {
        if (password.length < 8) {
            return "Password should be at least 8 character length";
        }
        return true;
    });
    defineRule("confirmed", (value, [target]: [any], ctx) => {
        if (value === ctx.form[target]) {
            return true;
        }
        return "Passwords doesn't match";
    });
    defineRule("phone", (phone: string) => {
        if (!/^[0-9-]+$/i.test(phone)) {
            return "Please enter a valid phone number without any ( ) or -";
        }
        return true;
    });
    defineRule("code", (code: string) => {
        if (!/^[0-9]+$/i.test(code)) {
            return "This field should contain numbers only";
        }
        return true;
    });
    defineRule("card-number", (value: string) => {
        if (!cardValidator.validateCardNumber(value)) {
            return "Invalid Card Number";
        }
        return true;
    });
    defineRule("card-expiration", (value: string) => {
        if (!cardValidator.validateCardExpiry(value)) {
            return "Invalid Expiration Date";
        }
        return true;
    });
    defineRule("card-cvc", (value: string) => {
        if (!cardValidator.validateCardCVC(value)) {
            return "Invalid CVC";
        }
        return true;
    });
    defineRule('minLength', (value: string, limit: number) => {
        if (!value || !value.length) {
            return true;
        }
        if (value.length < limit) {
            return `This field must be at least ${limit} characters`;
        }
        return true;
    });
}

start();