export const countriesMap = [
  ["CA", "Canada"],
  ["US", "United States"],
  ["AF", "Afghanistan"],
  ["AL", "Albania"],
  ["DZ", "Algeria"],
  ["AS", "American Samoa"],
  ["AD", "Andorra"],
  ["AO", "Angola"],
  ["AI", "Anguilla"],
  ["AQ", "Antarctica"],
  ["AG", "Antigua And Barbuda"],
  ["AR", "Argentina"],
  ["AM", "Armenia"],
  ["AW", "Aruba"],
  ["AU", "Australia"],
  ["AT", "Austria"],
  ["AZ", "Azerbaijan"],
  ["BS", "Bahamas"],
  ["BH", "Bahrain"],
  ["BD", "Bangladesh"],
  ["BB", "Barbados"],
  ["BY", "Belarus"],
  ["BE", "Belgium"],
  ["BZ", "Belize"],
  ["BJ", "Benin"],
  ["BM", "Bermuda"],
  ["BT", "Bhutan"],
  ["BO", "Bolivia"],
  ["BA", "Bosnia And Herzegovina"],
  ["BW", "Botswana"],
  ["BV", "Bouvet Island"],
  ["BR", "Brazil"],
  ["IO", "British Indian Ocean Ter."],
  ["BN", "Brunei Darussalam"],
  ["BG", "Bulgaria"],
  ["BF", "Burkina Faso"],
  ["BI", "Burundi"],
  ["KH", "Cambodia"],
  ["CM", "Cameroon"],
  ["CV", "Cape Verde"],
  ["KY", "Cayman Isl."],
  ["CF", "Central African Rep."],
  ["TD", "Chad"],
  ["CL", "Chile"],
  ["CN", "China"],
  ["CX", "Christmas Isl."],
  ["CC", "Cocos Isl."],
  ["CO", "Colombia"],
  ["KM", "Comoros"],
  ["CG", "Congo"],
  ["CD", "Congo, The Dem. Rep."],
  ["CK", "Cook Islands"],
  ["CR", "Costa Rica"],
  ["CI", "Cote D'ivoire"],
  ["HR", "Croatia"],
  ["CU", "Cuba"],
  ["CY", "Cyprus"],
  ["CZ", "Czech Republic"],
  ["DK", "Denmark"],
  ["DJ", "Djibouti"],
  ["DM", "Dominica"],
  ["DO", "Dominican Rep."],
  ["TP", "East Timor"],
  ["EC", "Ecuador"],
  ["EG", "Egypt"],
  ["SV", "El Salvador"],
  ["GQ", "Equatorial Guinea"],
  ["ER", "Eritrea"],
  ["EE", "Estonia"],
  ["ET", "Ethiopia"],
  ["FK", "Falkland Isl."],
  ["FO", "Faroe Isl."],
  ["FJ", "Fiji"],
  ["FI", "Finland"],
  ["FR", "France"],
  ["GF", "French Guiana"],
  ["PF", "French Polynesia"],
  ["TF", "French South. Ter."],
  ["GA", "Gabon"],
  ["GM", "Gambia"],
  ["GE", "Georgia"],
  ["DE", "Germany"],
  ["GH", "Ghana"],
  ["GI", "Gibraltar"],
  ["GR", "Greece"],
  ["GL", "Greenland"],
  ["GD", "Grenada"],
  ["GP", "Guadeloupe"],
  ["GU", "Guam"],
  ["GT", "Guatemala"],
  ["GN", "Guinea"],
  ["GW", "Guinea-bissau"],
  ["GY", "Guyana"],
  ["HT", "Haiti"],
  ["HM", "Heard Island"],
  ["VA", "Holy See"],
  ["HN", "Honduras"],
  ["HK", "Hong Kong"],
  ["HU", "Hungary"],
  ["IS", "Iceland"],
  ["IN", "India"],
  ["ID", "Indonesia"],
  ["IR", "Iran"],
  ["IQ", "Iraq"],
  ["IE", "Ireland"],
  ["IL", "Israel"],
  ["IT", "Italy"],
  ["JM", "Jamaica"],
  ["JP", "Japan"],
  ["JO", "Jordan"],
  ["KZ", "Kazakstan"],
  ["KE", "Kenya"],
  ["KI", "Kiribati"],
  ["KP", "Korea N"],
  ["KR", "Korea S"],
  ["KW", "Kuwait"],
  ["KG", "Kyrgyzstan"],
  ["LA", "Lao"],
  ["LV", "Latvia"],
  ["LB", "Lebanon"],
  ["LS", "Lesotho"],
  ["LR", "Liberia"],
  ["LY", "Libyan"],
  ["LI", "Liechtenstein"],
  ["LT", "Lithuania"],
  ["LU", "Luxembourg"],
  ["MO", "Macau"],
  ["MK", "Macedonia"],
  ["MG", "Madagascar"],
  ["MW", "Malawi"],
  ["MY", "Malaysia"],
  ["MV", "Maldives"],
  ["ML", "Mali"],
  ["MT", "Malta"],
  ["MH", "Marshall Isl."],
  ["MQ", "Martinique"],
  ["MR", "Mauritania"],
  ["MU", "Mauritius"],
  ["YT", "Mayotte"],
  ["MX", "Mexico"],
  ["FM", "Micronesia"],
  ["MD", "Moldova"],
  ["MC", "Monaco"],
  ["MN", "Mongolia"],
  ["MS", "Montserrat"],
  ["MA", "Morocco"],
  ["MZ", "Mozambique"],
  ["MM", "Myanmar"],
  ["NA", "Namibia"],
  ["NR", "Nauru"],
  ["NP", "Nepal"],
  ["NL", "Netherlands"],
  ["AN", "Netherlands Antilles"],
  ["NC", "New Caledonia"],
  ["NZ", "New Zealand"],
  ["NI", "Nicaragua"],
  ["NE", "Niger"],
  ["NG", "Nigeria"],
  ["NU", "Niue"],
  ["NF", "Norfolk Isl."],
  ["MP", "Northern Mariana Isl."],
  ["NO", "Norway"],
  ["OM", "Oman"],
  ["PK", "Pakistan"],
  ["PW", "Palau"],
  ["PS", "Palestinian Ter."],
  ["PA", "Panama"],
  ["PG", "Papua New Guinea"],
  ["PY", "Paraguay"],
  ["PE", "Peru"],
  ["PH", "Philippines"],
  ["PN", "Pitcairn"],
  ["PL", "Poland"],
  ["PT", "Portugal"],
  ["PR", "Puerto Rico"],
  ["QA", "Qatar"],
  ["RE", "Reunion"],
  ["RO", "Romania"],
  ["RU", "Russia"],
  ["RW", "Rwanda"],
  ["SH", "Saint Helena"],
  ["KN", "Saint Kitts"],
  ["LC", "Saint Lucia"],
  ["PM", "Saint Pierre"],
  ["VC", "Saint Vincent"],
  ["WS", "Samoa"],
  ["SM", "San Marino"],
  ["ST", "Sao Tome And Principe"],
  ["SA", "Saudi Arabia"],
  ["SN", "Senegal"],
  ["SC", "Seychelles"],
  ["SL", "Sierra Leone"],
  ["SG", "Singapore"],
  ["SK", "Slovakia"],
  ["SI", "Slovenia"],
  ["SB", "Solomon Isl."],
  ["SO", "Somalia"],
  ["ZA", "South Africa"],
  ["GS", "South Georgia"],
  ["ES", "Spain"],
  ["LK", "Sri Lanka"],
  ["SD", "Sudan"],
  ["SR", "Suriname"],
  ["SJ", "Svalbard And Jan Mayen"],
  ["SZ", "Swaziland"],
  ["SE", "Sweden"],
  ["CH", "Switzerland"],
  ["SY", "Syria"],
  ["TW", "Taiwan"],
  ["TJ", "Tajikistan"],
  ["TZ", "Tanzania"],
  ["TH", "Thailand"],
  ["TG", "Togo"],
  ["TK", "Tokelau"],
  ["TO", "Tonga"],
  ["TT", "Trinidad And Tobago"],
  ["TN", "Tunisia"],
  ["TR", "Turkey"],
  ["TM", "Turkmenistan"],
  ["TC", "Turks And Caicos Isl."],
  ["TV", "Tuvalu"],
  ["UG", "Uganda"],
  ["UA", "Ukraine"],
  ["AE", "United Arab Emirates"],
  ["GB", "United Kingdom"],
  ["UM", "Us Minor Outlying Isl."],
  ["UY", "Uruguay"],
  ["UZ", "Uzbekistan"],
  ["VU", "Vanuatu"],
  ["VE", "Venezuela"],
  ["VN", "Viet Nam"],
  ["VG", "Virgin Islands, Br."],
  ["VI", "Virgin Islands, Us."],
  ["WF", "Wallis And Futuna"],
  ["EH", "Western Sahara"],
  ["YE", "Yemen"],
  ["YU", "Yugoslavia"],
  ["ZM", "Zambia"],
  ["ZW", "Zimbabwe"],
];

export const statesMap = [
  ["AL", "Alabama (AL)"],
  ["AK", "Alaska (AK)"],
  ["AZ", "Arizona (AZ)"],
  ["AR", "Arkansas (AR)"],
  ["CA", "California (CA)"],
  ["CO", "Colorado (CO)"],
  ["CT", "Connecticut (CT)"],
  ["DE", "Delaware (DE)"],
  ["DC", "District of Columbia (DC)"],
  ["FL", "Florida (FL)"],
  ["GA", "Georgia (GA)"],
  ["GU", "Guam (GU)"],
  ["HI", "Hawaii (HI)"],
  ["ID", "Idaho (ID)"],
  ["IL", "Illinois (IL)"],
  ["IN", "Indiana (IN)"],
  ["IA", "Iowa (IA)"],
  ["KS", "Kansas (KS)"],
  ["KY", "Kentucky (KY)"],
  ["LA", "Louisiana (LA)"],
  ["ME", "Maine (ME)"],
  ["MD", "Maryland (MD)"],
  ["MA", "Massachusetts (MA)"],
  ["MI", "Michigan (MI)"],
  ["MN", "Minnesota (MN)"],
  ["MS", "Mississippi (MS)"],
  ["MO", "Missouri (MO)"],
  ["MT", "Montana (MT)"],
  ["NE", "Nebraska (NE)"],
  ["NV", "Nevada (NV)"],
  ["NH", "New Hampshire (NH)"],
  ["NJ", "New Jersey (NJ)"],
  ["NM", "New Mexico (NM)"],
  ["NY", "New York (NY)"],
  ["NC", "North Carolina (NC)"],
  ["ND", "North Dakota (ND)"],
  ["OH", "Ohio (OH)"],
  ["OK", "Oklahoma (OK)"],
  ["OR", "Oregon (OR)"],
  ["PA", "Pennyslvania (PA)"],
  ["PR", "Puerto Rico (PR)"],
  ["RI", "Rhode Island (RI)"],
  ["SC", "South Carolina (SC)"],
  ["SD", "South Dakota (SD)"],
  ["TN", "Tennessee (TN)"],
  ["TX", "Texas (TX)"],
  ["UT", "Utah (UT)"],
  ["VT", "Vermont (VT)"],
  ["VA", "Virginia (VA)"],
  ["VI", "Virgin Islands (VI)"],
  ["WA", "Washington (WA)"],
  ["WV", "West Virginia (WV)"],
  ["WI", "Wisconsin (WI)"],
  ["WY", "Wyoming (WY)"],
];

export const provincesMap = [
  ["AB", "Alberta (AB)"],
  ["BC", "British Columbia (BC)"],
  ["MB", "Manitoba (MB)"],
  ["NB", "New Brunswick (NB)"],
  ["NL", "Newfoundland-Labrador (NL)"],
  ["NT", "Northwest Territories (NT)"],
  ["NS", "Nova Scotia (NS)"],
  ["NU", "Nunavut (NU)"],
  ["PE", "Prince Edward Island (PE)"],
  ["SK", "Saskatchewan (SK)"],
  ["ON", "Ontario (ON)"],
  ["QC", "Quebec (QC)"],
  ["YT", "Yukon (YT)"],
];