import { Service } from "./service";

export class ConfigService extends Service {
    static async getConfig(): Promise<API.AppConfigResp> {
        try {
            const response = await ConfigService.get<API.AppConfigResp>("/config/get-config");
            if (response?.data?.data?.captchaConfig) {
                return response?.data?.data;
            } else {
                throw new Error("App config has wrong format");
            }
        } catch (err) {
            throw ConfigService.errorHandler(err);
        }
    }
    static async getInternalConfig(accessToken: string): Promise<API.AppInternalConfigResp> {
        try {
            const response = await ConfigService.get<API.AppInternalConfigResp>("/config/get-internal-config", accessToken);
            if (response?.data?.data) { //todo fix
                return response?.data?.data;
            } else {
                throw new Error("App internal config has wrong format");
            }
        } catch (err) {
            throw ConfigService.errorHandler(err);
        }
    }
}