import axios, { AxiosResponse } from "axios";

export class Service {
    static logoutMethod: () => void;

    static init(logoutMethod: () => void) {
        Service.logoutMethod = logoutMethod;
    }

    static authHeader(accessToken: string) {
        return { Authorization: accessToken };
    }
    static errorHandler(err: any) {
        let errorMessage;
        if (err?.response?.data?.error?.message) {
            errorMessage = err.response.data.error.message;
        } else if (err?.message) {
            errorMessage = err.message;
        } else {
            errorMessage = "Unknown error";
        }
        throw new Error(errorMessage);
    }

    static get<ResponseData>(url: string, accessToken?: string): Promise<AxiosResponse<API.ResponseSuccess<ResponseData>>> {
        return axios({
            url: url,
            method: "get",
            headers: accessToken ? Service.authHeader(accessToken) : undefined,
            validateStatus: accessToken ? Service.validateStatusAuth : Service.validateStatusDefault
        });
    }
    static post<RequestData, ResponseData>(url: string, data: RequestData, accessToken?: string): Promise<AxiosResponse<API.ResponseSuccess<ResponseData>>> {
        return axios({
            url: url,
            data: data,
            method: "post",
            headers: accessToken ? Service.authHeader(accessToken) : undefined,
            validateStatus: accessToken ? Service.validateStatusAuth : Service.validateStatusDefault
        });
    }
    static validateStatusAuth = (status: number) => {
        if (status === 401) { //unauthorized
            if (Service.logoutMethod) {
                Service.logoutMethod();
            } else {
                console.error("No logout method");
            }
        }
        return status >= 200 && status < 300;
    }
    static validateStatusDefault = (status: number) => {
        return status >= 200 && status < 300;
    }
}