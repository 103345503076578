import { Service } from "./service";

export class SignupService extends Service {
    static async register(signupData: API.RegisterReq): Promise<API.RegisterResp> {
        try {
            const response = await SignupService.post<API.RegisterReq, API.RegisterResp>("/signup/register", signupData);
            if (response?.data?.data?.success) {
                return response.data.data;
            } else {
                throw new Error("Signup error");
            }
        } catch (err) {
            throw SignupService.errorHandler(err);
        }
    }
    static async verifyEmail(accessToken: string, verifyEmailData: API.VerifyEmailReq): Promise<API.VerifyEmailResp> {
        try {
            const response = await SignupService.post<API.VerifyEmailReq, API.VerifyEmailResp>("/signup/verify-email", verifyEmailData, accessToken);
            if (response?.data?.data?.success) {
                return response.data.data;
            } else {
                throw new Error("Verify email error");
            }
        } catch (err) {
            throw SignupService.errorHandler(err);
        }
    }
    static async resendEmailOtp(accessToken: string, resendEmailOtpData: API.ResendEmailOtpReq): Promise<API.ResendEmailOtpResp> {
        try {
            const response = await SignupService.post<API.ResendEmailOtpReq, API.ResendEmailOtpResp>("/signup/resend-email-otp", resendEmailOtpData, accessToken);
            if (response?.data?.data?.success) {
                return response.data.data;
            } else {
                throw new Error("Resend email Otp error");
            }
        } catch (err) {
            throw SignupService.errorHandler(err);
        }
    }
    static async changeEmail(accessToken: string, changeEmailData: API.ChangeEmailReq): Promise<API.ChangeEmailResp> {
        try {
            const response = await SignupService.post<API.ChangeEmailReq, API.ChangeEmailResp>("/signup/change-email", changeEmailData, accessToken);
            if (response?.data?.data?.success) {
                return response.data.data;
            } else {
                throw new Error("Change email error");
            }
        } catch (err) {
            throw SignupService.errorHandler(err);
        }
    }
    static async activateTrial(accessToken: string, selectedPlan: string): Promise<API.ActivateTrialResp> {
      try {
        const response = await SignupService.post<API.ActivateTrialReq, API.ActivateTrialResp>("/signup/activate-trial", {plan: selectedPlan} , accessToken);
        if (response?.data?.data?.success && response?.data?.data?.subscription) {
          return response?.data?.data;
        } else {
          throw new Error("Error activating subscription. Your card was declined");
        }
      } catch (err) {
        throw SignupService.errorHandler(err);
      }
    }
}