import {defineStore} from 'pinia';
import {router} from '@/router';
import {UserService} from '../services/user';
import {useUserStore} from './user';
import {useSignupMachine} from '../components/signup/signup_fsm';

export interface AuthState {
    accessToken: string | null;
}

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => {
        return {
            accessToken: null
        };
    },
    actions: {
        async login(credentials: API.LoginReq) {
            try {
                const userData = await UserService.signIn(credentials);
                if (userData?.accessToken) {
                    this.checkActivation(userData.user);
                    this.loginSuccess(userData.accessToken, userData.user);
                } else {
                    this.loginFailure();
                }
            } catch (err) {
                this.loginFailure();
                throw err;
            }
        },
        async checkActivation(userData: API.UserData) {
            try {
                if (
                    userData?.profile?.activationState === "email" ||
                    userData?.profile?.activationState === "phone-enter" ||
                    userData?.profile?.activationState === "phone-code"
                ) {
                    const signupFsm = useSignupMachine();
                    signupFsm.send({type: "INIT_SUCCESS", email: userData.profile.email});
                    if (userData?.profile?.activationState === "phone-enter") signupFsm.send({
                        type: "INIT_SUCCESS",
                        email: userData.profile.email
                    });
                    if (userData?.profile?.activationState === "phone-code") {
                        signupFsm.send({type: "INIT_SUCCESS", email: userData.profile.email});
                    }
                }
            } catch (err) {
                this.loginFailure();
                throw err;
            }
        },
        async initialize() {
            try {
                const accessToken = this.getToken();
                if (accessToken) {
                    const response = await UserService.getUserdata(accessToken);
                    if (response.success && response.userdata) {
                        this.checkActivation(response.userdata);
                        this.loginSuccess(accessToken, response.userdata);
                    } else {
                        this.loginFailure();
                    }
                } else {
                    this.loginFailure();
                }
            } catch (err) {
                this.loginFailure();
            }
        },
        logout() {
            this.removeToken();
            const userStore = useUserStore();
            userStore.clearAll();
            router.push("login");
            if (window.revgems)
                window.revgems("logout");
        },
        loginSuccess(accessToken: string, userData: API.UserData) {
            this.setToken(accessToken);
            const userStore = useUserStore();
            userStore.setAll(userData);
        },
        loginFailure() {
            this.removeToken();
            const userStore = useUserStore();
            userStore.clearAll();
        },
        setToken(accessToken: string) {
            localStorage.setItem("accessToken", accessToken);  //todo obtain token from secure place
            this.accessToken = accessToken;
        },
        removeToken() {
            localStorage.removeItem("accessToken");  //todo obtain token from secure place
            this.accessToken = null;
        },
        getToken() {
            return localStorage.getItem('accessToken'); //todo obtain token from secure place
        }
    }
});

export function getAccessToken() {
    const auth = useAuthStore();
    if (!auth.accessToken) {
        throw new Error("No access token");
    }
    return auth.accessToken;
}