import { Ref } from "vue";
import RecaptchaBlock from "../signup/RecaptchaBlock.vue";

export interface States {
    loadingShow: Ref<boolean>;
    errorMessage: Ref<string>;
    captchaEnabled: boolean;
    recaptcha: Ref<InstanceType<typeof RecaptchaBlock> | null> | null;
}

export async function submitHandler<T = void>(
    actionHandler: () => Promise<T>,
    states: States,
    successHandler?: (response: T) => Promise<any>,
    errorHandler?: (err: unknown) => Promise<void>
): Promise<void> {
    try {
        states.errorMessage.value = "";
        if (states.captchaEnabled && !(states.recaptcha?.value?.token())) {
            states.errorMessage.value = "Please complete captcha to proceed";
            return;
        }

        states.loadingShow.value = true;
        try {
            const response: T = await actionHandler();
            if (successHandler) {
                await successHandler(response);
            }
        } catch (err) {
            states.recaptcha?.value?.reset();
            if (errorHandler) {
                await errorHandler(err);
            } else {
                if (err instanceof Error) {
                    states.errorMessage.value = err.message;
                }
            }
        }
        states.loadingShow.value = false;
    } catch (err) {
        console.error(err);
    }
}

export type Captcha = { captcha: string } | {};

export function getCaptcha(states: States): Captcha {
    return states.captchaEnabled ? { captcha: states.recaptcha?.value?.token() } : {};
}