<template>
  <div ref="root"></div>
</template>
<script>
import { useConfigStore } from "@/store/config";
import { defineComponent, ref, onMounted } from "vue";

function _extends() {
  // eslint-disable-next-line no-func-assign
  _extends =
    Object.assign ||
    function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];

        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }

      return target;
    };

  return _extends.apply(this, arguments);
}

var defer = function defer() {
  var state = false; // Resolved or not

  var callbacks = [];

  var resolve = function resolve() {
    if (state) {
      return;
    }

    state = true;

    for (var i = 0, len = callbacks.length; i < len; i++) {
      callbacks[i]();
    }
  };

  var then = function then(cb) {
    if (!state) {
      callbacks.push(cb);
      return;
    }

    cb();
  };

  var deferred = {
    resolved: function resolved() {
      return state;
    },
    resolve: resolve,
    promise: {
      then: then,
    },
  };
  return deferred;
};

var ownProp = Object.prototype.hasOwnProperty;
function createRecaptcha() {
  var deferred = defer();
  return {
    notify: function notify() {
      deferred.resolve();
    },
    wait: function wait() {
      return deferred.promise;
    },
    render: function render(ele, options, cb) {
      this.wait().then(function () {
        cb(window.grecaptcha.render(ele, options));
      });
    },
    reset: function reset(widgetId) {
      if (typeof widgetId === "undefined") {
        return;
      }

      this.assertLoaded();
      this.wait().then(function () {
        return window.grecaptcha.reset(widgetId);
      });
    },
    execute: function execute(widgetId) {
      if (typeof widgetId === "undefined") {
        return;
      }

      this.assertLoaded();
      this.wait().then(function () {
        return window.grecaptcha.execute(widgetId);
      });
    },
    checkRecaptchaLoad: function checkRecaptchaLoad() {
      if (
        ownProp.call(window, "grecaptcha") &&
        ownProp.call(window.grecaptcha, "render")
      ) {
        this.notify();
      }
    },
    assertLoaded: function assertLoaded() {
      if (!deferred.resolved()) {
        throw new Error("ReCAPTCHA has not been loaded");
      }
    },
  };
}
var recaptcha = createRecaptcha();

if (typeof window !== "undefined") {
  window.vueRecaptchaApiLoaded = recaptcha.notify;
}

export default defineComponent({
  name: "VueRecaptcha",
  props: {
    theme: {
      type: String,
    },
    badge: {
      type: String,
    },
    type: {
      type: String,
    },
    size: {
      type: String,
    },
    tabindex: {
      type: String,
    },
    recaptchaScriptId: {
      type: String,
      default: "__RECAPTCHA_SCRIPT",
    },
    recaptchaHost: {
      type: String,
      default: "www.google.com",
    },
    language: {
      type: String,
      default: "",
    },
  },
  emits: ["render", "verify", "expired", "error"],
  setup(props, _ref) {
    const config = useConfigStore();
    var slots = _ref.slots,
      emit = _ref.emit;
    var root = ref(null);
    var widgetId = ref(null);

    var token = "";

    var emitVerify = function emitVerify(response) {
      token = response;
    };

    var emitExpired = function emitExpired() {
      token = null;
    };

    var emitError = function emitError() {
      token = null;
    };

    onMounted(function () {
      recaptcha.checkRecaptchaLoad();

      if (!document.getElementById(props.recaptchaScriptId)) {
        // Note: vueRecaptchaApiLoaded load callback name is per the latest documentation
        var script = document.createElement("script");
        script.id = props.recaptchaScriptId;
        script.src =
          "https://" +
          props.recaptchaHost +
          "/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=" +
          props.language;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      }

      var opts = _extends({}, props, {
        sitekey: config.captcha.key,
        callback: emitVerify,
        "expired-callback": emitExpired,
        "error-callback": emitError,
      });

      var $root = root.value;
      var container = slots["default"] ? $root.children[0] : $root;
      recaptcha.render(container, opts, function (id) {
        widgetId.value = id;
        emit("render", id);
      });
    });
    return {
      root: root,
      widgetId: widgetId,
      reset: function reset() {
        token = null;
        recaptcha.reset(widgetId.value);
      },
      execute: function execute() {
        recaptcha.execute(widgetId.value);
      },
      token: function () {
        return token;
      },
    };
  },
});
</script>
